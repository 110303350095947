import React from 'react';
import { Flex, Box, Heading, Text } from '@chakra-ui/react';

const HomeTile = ({ subjects, index, children }) => {
  const pictureToLeft = index !== 0 && index % 4 !== 0;
  const white = index % 2 == 0;
  return (
    <Flex
      flexDir={pictureToLeft ? 'row' : 'row-reverse'}
      flexWrap={'wrap'}
      justifyContent={{
        base: 'center',
        sm: children ? 'space-between' : 'center',
      }}
      w="100%"
      gap={16}
      mt={{
        base: 0,
        lg: index === 0 ? 0 : 16,
      }}
      backgroundColor={white ? '#fff' : '#f1f1f1'}
      pt={{
        base: 8,
        lg: 16,
      }}
      pb={{
        base: 8,
        lg: 16,
      }}
    >
      <Flex
        flexDir="column"
        gap={4}
        justifyContent="center"
        ms={{
          base: 0,
          sm: children ? (pictureToLeft ? 4 : 0) : subjects.length > 1 ? 4 : 0,
          lg: children
            ? pictureToLeft
              ? 16
              : 0
            : subjects.length > 1
            ? 16
            : 0,
        }}
        me={{
          base: 0,
          sm: children ? (pictureToLeft ? 0 : 4) : subjects.length > 1 ? 4 : 0,
          lg: children
            ? pictureToLeft
              ? 0
              : 16
            : subjects.length > 1
            ? 16
            : 0,
        }}
        w={{
          base: '350px',
          sm: children ? '40%' : '100%',
        }}
      >
        <Flex
          flexDir={{
            base: 'column',
            sm: children ? 'column' : 'row',
          }}
          gap="16"
        >
          {subjects.map((subject, index) => (
            <Flex flexDir="column" gap="8" w="100%" key={index}>
              <Heading>{subject.title}</Heading>
              <Text whiteSpace="pre-line" fontSize={18}>
                {subject.description}
              </Text>
            </Flex>
          ))}
        </Flex>
      </Flex>
      {children && (
        <Flex
          w={{
            base: '350px',
            sm: '40%',
          }}
          alignItems="center"
        >
          <Box>{children}</Box>
        </Flex>
      )}
    </Flex>
  );
};

export default HomeTile;
